import {createStyles, Group, Button, keyframes} from "@mantine/core";
import {Icon} from "@iconify/react";
import LeftIcon from '@iconify-icons/material-symbols/chevron-left-rounded'
import RightIcon from '@iconify-icons/material-symbols/chevron-right-rounded'
import DownIcon from '@iconify-icons/material-symbols/keyboard-arrow-down-rounded'
import CloseIcon from '@iconify-icons/material-symbols/close'
import { useWindowEvent } from '@mantine/hooks';
import {useState} from "react";

const bounceX = keyframes({
    'from': { transform: 'translateX(0)' },
    'to': { transform: 'translateX(-10px)' },
});
const bounceY = keyframes({
    'from': { transform: 'translateY(0)' },
    'to': { transform: 'translateY(10px)' },
});

export const useStyles = createStyles((theme) => ({
    container: {
        position: 'fixed',
        zIndex: 200,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'rgba(0,0,0,0.8)',
        color: '#ffffff',
    },
    mainBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '0 16px',
    },
    indicator: {
        position: 'absolute',
        top: 24,
        right: 80,
    },
    scrolling: {
        position: 'absolute',
        bottom: 32,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    textLabel: {
        fontSize: theme.fontSizes.sm,
        textAlign: 'center',
    },
    bounceY: {
        animation: `${bounceY} 1s linear infinite alternate`,
    },
    bounceLeft: {
        animation: `${bounceX} 1s linear infinite alternate`,
    },
    bounceRight: {
        animation: `${bounceX} 1s linear infinite alternate-reverse`,
    }
}));

export function InstructionView() {
    const { classes } = useStyles();
    const [ isClosed, setClose ] = useState(false);

    const onClose = () => {
        setClose(true);
    };

    useWindowEvent('scroll', () => {
        if (!isClosed) onClose();
    });

    if (isClosed) return null;

    return (
        <div className={classes.container}>
            {/* Top-right indicator */}
            <div className={classes.indicator}>
                <Group spacing={0}>
                    <div className={classes.textLabel}>Indicator of the current world</div>
                    <Icon icon={RightIcon} width={36} />
                </Group>
            </div>

            {/* Center */}
            <div className={classes.mainBody}>

                <Group className={classes.slideBody}>
                    <Icon icon={LeftIcon} width={36} className={classes.bounceLeft} />
                    <div className={classes.textLabel}>
                        Slide left or right <br />to see different worlds
                    </div>
                    <Icon icon={RightIcon} width={36} className={classes.bounceRight} />
                </Group>
            </div>

            {/* Bottom scrolling reminder */}
            <div className={classes.scrolling}>
                {/* Close button */}
                <Button mb={48} size="xs" variant="default" radius="md" className={classes.closeBtn}
                        leftIcon={<Icon icon={CloseIcon} width={18} />}
                        onClick={onClose}
                >
                    Close
                </Button>

                <div className={classes.textLabel}>
                    Scroll down to <br />walk through the story
                </div>
                <Icon icon={DownIcon} width={36} className={classes.bounceY} />
            </div>
        </div>
    )
}