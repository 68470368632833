import {Outlet, useParams} from "react-router-dom";
import {useAuth} from "@/hooks/auth";
import useSWR from "swr";
import api from "@/lib/api";
import {ErrorScreen} from "@/components/error";
import ScrollingStoryView from "@polyusd-parable/scrolling-story-view/view";
import ResLoadingView from "../../packages/scrolling-story-view/loading";
import {useViewportSize} from "@mantine/hooks";
import StoryEndView from "@/components/story/ending";
import {useMemo} from "react";
import {useMantineTheme} from "@mantine/core";

async function fetchStory(key, storyId) {
    return (await api.get(`/frontend/story/${storyId}`)).data?.data;
}

export default function StoryIndexPage() {
    const theme = useMantineTheme();
    const {} = useAuth({ middleware: 'auth' });
    const {storyId} = useParams();
    const {data: story, error} = useSWR(['story', storyId], fetchStory);
    const { height: vpHeight, width: vpWidth } = useViewportSize();

    const canvasSize = useMemo(() => {
        if (vpWidth >= theme.breakpoints.md) {
            return { width: 500, height: vpHeight };
        }
        return { width: vpWidth, height: vpHeight };
    }, [vpWidth, vpHeight, theme.breakpoints.md]);

    if (error) {
        return (
            <ErrorScreen
                title="Failed to fetch story data"
                message="Sorry! We're unable to retrieve the story at this moment. Please make sure you have a stable Internet connection, or try again later."
            />
        )
    } else if (!story || vpHeight === 0) {
        return <ResLoadingView progress={0} />;
    }

    return (
        <>
            <ScrollingStoryView
                key={story.id}
                iterations={story.iterations}
                width={canvasSize.width}
                height={canvasSize.height}
                append={<StoryEndView />}
                showInstruction={true}
            />
            <Outlet />
        </>
    );
}