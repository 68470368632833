import {useStyles} from "./styles";

export default function IterationCover({className, title}) {
    const { classes } = useStyles();

    return (
        <div className={classes.cover}>
            <div className={classes.coverBody}>
                {title}
            </div>
        </div>
    )
}