import {createStyles, Group, Progress, Text} from "@mantine/core";
import {useMemo} from "react";

const useStyles = createStyles((theme) => ({
    progressBarWrapper: {
        flex: 1,
        color: '#ffffff',
    },
    label: {
        fontSize: theme.fontSizes.xs,
    },
    progressCompletedVal: {
        fontSize: theme.fontSizes.xl,
        fontWeight: 'bold',
        lineHeight: 1,
    },
    progressTotalVal: {
        fontSize: theme.fontSizes.sm,
        lineHeight: 1,
    },
    progressBar: {
        border: `1px solid ${theme.colors.gray[2]}`
    },
}));

export default function UserProgress({profile}) {
    const { classes } = useStyles();
    const percentage = useMemo(() => {
        return profile.completed.length / profile.available.length * 100;
    }, [profile.completed, profile.available]);
    console.log(percentage)

    return (
        <div className={classes.progressBarWrapper}>
            <Group position="apart">
                <div className={classes.label}>Your progress</div>
                <div>
                    <span className={classes.progressCompletedVal}>{profile.completed.length} </span>
                    <span className={classes.progressTotalVal}>/ {profile.available.length}</span>
                </div>
            </Group>
            <Progress value={percentage} color="green" size="lg" radius="xl" className={classes.progressBar} />
        </div>
    );
}