import { Radio } from "@mantine/core";
import {questionFormatter} from "../helper";
import {useSchemaFormContext} from "../form";
import Joi from "joi";

function Field({ item, index, classes }) {
    const form = useSchemaFormContext();

    return (
        <Radio.Group description={item.description}
                     label={questionFormatter({item, index})}
                     orientation="vertical" size="md"
                     {...form.getInputProps(item.id)}>
            <Radio value="1" label={item.label_true} />
            <Radio value="0" label={item.label_false} />
        </Radio.Group>
    );
}

function buildSchema(field) {
    return Joi.boolean()
        .truthy('1').falsy('0')
        .required()
        .messages({ 'any.required': 'This is a mandatory field' });
}

export default {
    Field,
    buildSchema,
}