import api from "../lib/api";
import useSWR from 'swr'
import {useEffect, useState, useMemo} from "react";
import {useNavigate} from "react-router-dom";

export const useAuth = ({ middleware } = {}) => {
    const navigate = useNavigate();

    const [_isReady, setIsReady] = useState(false);
    const csrf = () => api.get('/sanctum/csrf-cookie');

    const { data: profile, error, mutate } = useSWR('/frontend/profile', () =>
        api.get('/frontend/profile')
            .then(res => {
                return res.data;
            })
            .finally(() => {
                setIsReady(true);
            }),
    )

    const isReady = useMemo(() => _isReady || !!profile, [_isReady, profile]);

    const login = async (data) => {
        await csrf();
        return api.post('/frontend/login', data)
            .then(() => mutate());
    }

    const logout = async () => {
        if (!error) {
            await api.post('/frontend/logout').then(() => mutate())
        }

        window.location.pathname = '/'
    }

    useEffect(() => {
        if (middleware === 'guest' && profile) navigate('/')
        if (middleware === 'auth' && error) logout()
    }, [middleware, profile, error])

    return {
        profile,
        login,
        isReady,
        profileMutation: mutate,
    };
};