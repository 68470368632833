const fontFamily = '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji';
const headingsFontFamily = '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji';

/**
 * @type {import("@mantine/core").MantineThemeOverride}
 */
export const theme = {
    colorScheme: 'light',
    colors: {
        primary: ['#d8feff', '#acf4ff', '#7decff', '#4de4ff', '#27dcfe', '#16c3e5', '#0097b3', '#006c81', '#00424f', '#00181e'],
        secondary: ['#e8f3fe', '#cbd7e8', '#abbdd4', '#8aa2c1', '#6a88af', '#506e95', '#3e5675', '#2b3d54', '#182534', '#040c17'],
        accent: ['#e4f6ff', '#c6dfea', '#a6c8d8', '#85b2c7', '#649cb6', '#4a829c', '#38657a', '#264858', '#122c37', '#001018'],
    },
    primaryColor: 'primary',
    // primaryShade: 5,
    // defaultRadius: 'md',
    components: {
        Title: {
            styles: {
                root: {
                    color: '#008CA5',
                }
            }
        },
        Button: {
            defaultProps: { radius: 'lg', size: 'lg' }
        },
        // Card: {
        //     defaultProps: { radius: 'lg', shadow: 'sm', withBorder: true },
        // },
        TextInput: {
            defaultProps: { radius: 'lg', size: 'lg' },
        },
        'Input.Wrapper': {

        },
        Container: {
            defaultProps: { size: 500 },
        }
    },
    fontFamily: fontFamily,
    headings: {
        fontFamily: headingsFontFamily,
        fontWeight: 700,
    },
    other: {
        headerHeight: 56,
    }
};
