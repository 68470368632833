import {Box, Container, Text, Title, useMantineTheme} from "@mantine/core";
import {Icon} from "@iconify/react";
import MedalIcon from "@iconify-icons/mdi/medal-outline";

export function AllCompletedBody() {
    const theme = useMantineTheme();

    return (
        <Container>
            <Box py="xl" style={{ textAlign: 'center' }}>
                <Icon icon={MedalIcon} width={64} height={64} color={theme.colors.primary[6]} />
                <Title order={2} align="center">Congratulations!</Title>
                <Text size="sm" color="dimmed" align="center">
                    You have completed all surveys. <br />Thank you for your time!
                </Text>
            </Box>
        </Container>
    );
}
