import {Group, Slider, Text} from "@mantine/core";
import {questionFormatter} from "../helper";
import {useMemo} from "react";
import {useSchemaFormContext} from "../form";
import Joi from "joi";

function Field({ item, index, classes }) {
    const form = useSchemaFormContext();

    const min = item.min_value;
    const max = item.max_value;

    const marks = useMemo(() => {
        return [...Array(max-min+1).keys()].map((i) => (
            { value: min + i }
        ));
    }, [min, max, item.labels]);

    const label = function (value) {
        return item.labels[value-min];
    };

    return (
        <>
            <Text className={classes.question}>{questionFormatter({item, index})}</Text>
            <Slider size="xl" min={min} max={max}
                    label={label} marks={marks}
                    classNames={{ label: classes.scaleLabel, bar: classes.scaleBar, markFilled: classes.scaleMarkFilled }}
                    {...form.getInputProps(item.id)} />
            <Group position="apart" mt="xs">
                <Text className={classes.scaleMark}>{item.labels[0]}</Text>
                <Text className={classes.scaleMark}>{item.labels[max-min]}</Text>
            </Group>
        </>
    );
}

function buildSchema(field) {
    return Joi.number()
        .min(field.min_value)
        .max(field.max_value);
}

export default {
    Field,
    buildSchema
}