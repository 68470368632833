import {useEffectOnce} from "react-use";
import {computeMarks, findImagesFromIterations, preloadImages} from "./helper";
import {useMemo, useState} from "react";
import ResLoadingView from "./loading";
import {IterationView, StoryMain} from "./iteration/container";
import ViewIndicator from "./ui/indicator";
import {StoryContext, WORLD_BLENDED} from "./context";
import {useSetState, useWindowEvent} from "@mantine/hooks";
import {InstructionView} from "./ui/instruction";

const perFrame = 140;

export default function ScrollingStoryView({ iterations, width, height, append, showInstruction }) {
    const [loadingRes, setLoadingRes] = useState(true);
    const [isReady, setReady] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [state, setState] = useSetState({
        world: WORLD_BLENDED,
        worldProgress: 0,
        isWorldTransition: false,
        iteration: 0,
        progress: 0,
        resCache: {},
    });

    const iterationsData = useMemo(() => computeMarks(iterations, perFrame, height), [iterations, perFrame, height]);

    useEffectOnce(() => {
        // Get URL of all images from iterations definition
        const images = findImagesFromIterations(iterations);

        // Preload all images
        setLoadingRes(true);
        preloadImages(images, {
            onProgress: function (progress) {
                setPercentage(Math.round(progress.success / progress.total * 100));
            }
        })
            .then(function (result) {
                const map = {};
                for (let row of result) {
                    if (row.status === 'fulfilled') {
                        map[row.value['id']] = row.value['object'];
                    }
                }
                setState({ resCache: map });

                // Check if failed to load any images
                const failedCount = result.filter(i => i.status === 'rejected').length;
                if (failedCount > 0) {
                    // Log in console only
                    console.error(`[Image preload] Failed to load ${failedCount} image(s)`);
                }

                setReady(true);
            }).catch(function (e) {
                console.log('load error', e);
            }).finally(function () {
                setLoadingRes(false);
            });
    });

    useWindowEvent("scroll", function () {
        const y = window?.scrollY || 0;

        for (let i in iterationsData) {
            const data = iterationsData[i];
            if (y >= data.start && y <= data.end) {
                setState({
                    iteration: i,
                    progress: Math.min((y - data.start) / (data.end - height - data.start), 1)
                });
                break;
            }
        }
    });

    if (loadingRes) {
        return (
            <ResLoadingView progress={percentage} />
        );
    }

    return (
        <StoryContext.Provider value={{ state, setState }}>
            <ViewIndicator />
            {showInstruction ? <InstructionView /> : null}

            <StoryMain width={width} height={height} iterations={iterations}>
                {iterations.map((iteration, k) => <IterationView key={k} iterationI={k} iteration={iteration} height={iterationsData[k].height} />)}
            </StoryMain>
            {append}

            {/*<div style={{ position: 'fixed', zIndex: 999, bottom: 0, left: 0, background: 'rgba(0,0,0,0.4)' }}>
                i: {state.iteration} / p: {state.progress}
            </div>*/}
        </StoryContext.Provider>
    );
}