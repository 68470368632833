import AppHeader from "@/components/layout/header";
import {Button, Container, createStyles, Center, Loader, Text} from "@mantine/core";
import {useAuth} from "@/hooks/auth";
import {useNextStory} from "@/hooks/story";
import {AllCompletedBody} from "@/components/message/AllCompletedBody";
import {useMemo} from "react";

const useStyles = createStyles((theme) => ({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    title: {
        textAlign: 'center',
        fontWeight: theme.headings.fontWeight,
        fontSize: theme.headings.sizes.h2.fontSize,
        lineHeight: theme.headings.sizes.h2.lineHeight,
        color: theme.colors.primary[6]
    },
}));

function Body() {
    const {classes} = useStyles();
    const {goToNextStory} = useNextStory();

    return (
        <Container className={classes.container}>
            <Text size="xl" mb="md" className={classes.title}>
                Ready for the next story?
            </Text>
            <Button fullWidth onClick={goToNextStory}>
                Start
            </Button>
        </Container>
    );
}

export default function UserHomePage() {
    const {profile, isReady} = useAuth({ middleware: 'auth' });
    const isAllCompleted = useMemo(
        () => profile && profile.completed.length >= profile.available.length,
        [profile, profile?.completed, profile?.available]
    );

    return (
        <>
            <AppHeader />
            {isReady
                ? (isAllCompleted ? <AllCompletedBody /> : <Body />)
                : <Center py="md"><Loader /></Center>}
        </>
    );
}