import {createStyles} from "@mantine/core";
import {useContext} from "react";
import {StoryContext, WORLD_BEHAVIOUR, WORLD_BLENDED, WORLD_COMPARABLE} from "../context";

export const useStyles = createStyles((theme) => ({
    indicators: {
        display: 'flex',
        position: 'fixed',
        zIndex: 15,
        right: 0,
        top: 20,
        background: '#06357a',
        borderRadius: '10px 0 0 10px',
        padding: 10,
        opacity: 0.3,
    },
    indicator: {
        width: 20,
        height: 20,
        borderRadius: 10,
        border: '1px solid #ffffff',
        transition: 'background 0.3s',
        margin: '0 4px',
        cursor: 'pointer',
    },
    indicatorActive: {
        background: '#ffffff',
    }
}));

export default function ViewIndicator() {
    const {classes, cx} = useStyles();
    const {state, setState} = useContext(StoryContext);

    const changeWorld = function (e) {
        setState({world: e});
    }

    return (
        <div className={classes.indicators}>
            <div onClick={() => changeWorld(WORLD_BEHAVIOUR)}
                 className={cx({[classes.indicator]: true, [classes.indicatorActive]: state.world === WORLD_BEHAVIOUR})}></div>
            <div onClick={() => changeWorld(WORLD_BLENDED)}
                 className={cx({[classes.indicator]: true, [classes.indicatorActive]: state.world === WORLD_BLENDED})}></div>
            <div onClick={() => changeWorld(WORLD_COMPARABLE)}
                 className={cx({[classes.indicator]: true, [classes.indicatorActive]: state.world === WORLD_COMPARABLE})}></div>
        </div>
    );
}