import Joi from 'joi';
import { joiResolver } from '@mantine/form';
import {fieldTypes} from "./types";

export function buildInitialValues(schema) {
    return schema.items.reduce(function (acc, field) {
        acc[field.id] = field.default_value || undefined;
        return acc;
    }, {});
}

export function buildValidators(schema) {
    const joiSchema = Joi.object(
        schema.items.reduce(function (acc, field) {
            acc[field.id] = fieldTypes[field.type].buildSchema(field);
            return acc;
        }, {})
    );

    return joiResolver(joiSchema);
}