import { Stage } from 'react-konva';
import {createContext, useContext, useMemo} from "react";
import {StoryContext, WORLD_BEHAVIOUR, WORLD_BLENDED, WORLD_COMPARABLE} from "../context";
import {StoryLayer} from "./layer";
import {useStyles} from "./styles";
import {useSetState} from "@mantine/hooks";

export const StoryCanvasContext = createContext(null);

export function StoryCanvas({ iterations, width, height }) {
    const { state, setState } = useContext(StoryContext);
    const { classes } = useStyles();

    const iteration = iterations[state.iteration];
    const layers = useMemo(() => iteration.layers.slice().reverse(), [iteration.layers]);

    const [ swipeState, setSwipeState ] = useSetState({
        startX: 0,
        lastX: 0,
        startY: 0,
        lastY: 0,
        isScrolling: false,
    });
    const thresholdChangeState = 100;
    const thresholdScroll = 20;

    const handleGestureEnd = function () {
        const delta = swipeState.lastX - swipeState.startX;
        if (Math.abs(delta) > thresholdChangeState) {
            let world = state.world;
            if (delta > 0) {
                // to right
                if (world === WORLD_BEHAVIOUR) world = WORLD_BLENDED;
                else if (world === WORLD_BLENDED) world = WORLD_COMPARABLE;
            } else {
                // to left
                if (world === WORLD_BLENDED) world = WORLD_BEHAVIOUR;
                else if (world === WORLD_COMPARABLE) world = WORLD_BLENDED;
            }

            setState({ world, worldProgress: 0, isWorldTransition: false });
        } else {
            setState({ worldProgress: 0, isWorldTransition: false });
        }
    };

    /*
        Mouse event
     */
    const onMouseDown = function (e) {
        setSwipeState({
            startX: e.clientX,
            startY: e.clientY,
            lastX: e.clientX,
            lastY: e.clientY,
        });
        setState({ isWorldTransition: true });
    }
    const onMouseMove = function (e) {
        // Prevent mouse is not down
        if (!state.isWorldTransition) return;

        setSwipeState({ lastX: e.clientX, lastY: e.clientY });

        const delta = swipeState.lastX - swipeState.startX;
        let progress = Math.max(-1, Math.min(1, delta / (width * 0.8)));

        // Check if it is in the leftmost/rightmost world already
        if ((state.world === WORLD_BEHAVIOUR && progress < 0) || (state.world === WORLD_COMPARABLE && progress > 0)) {
            return;
        }

        setState({ worldProgress: progress });
    };
    const onMouseUp = function (e) {
        // Prevent mouse is not down
        if (!state.isWorldTransition) return;

        handleGestureEnd();
    };

    /*
        Touch event
     */
    const onTouchStart = function (e) {
        setSwipeState({
            startX: e.touches[0].clientX,
            startY: e.touches[0].clientY,
            lastX: e.touches[0].clientX,
            lastY: e.touches[0].clientY,
            isScrolling: false,
        });
        setState({ isWorldTransition: true });
    };
    const onTouchMove = function (e) {
        // Prevent transition if scrolling is detected in previous frame
        if (swipeState.isScrolling) return;

        setSwipeState({ lastX: e.touches[0].clientX, lastY: e.touches[0].clientY });

        // Prevent transition if scrolling
        if (Math.abs(swipeState.lastY - swipeState.startY) > thresholdScroll) {
            setState({ worldProgress: 0, isWorldTransition: false });
            setSwipeState({ isScrolling: true });
            return;
        }

        const delta = swipeState.lastX - swipeState.startX;
        if (Math.abs(delta) < 20) {
            return;
        }

        let progress = Math.max(-1, Math.min(1, delta / 200));

        // Check if it is in the leftmost/rightmost world already
        if ((state.world === WORLD_BEHAVIOUR && progress < 0) || (state.world === WORLD_COMPARABLE && progress > 0)) {
            return;
        }

        setState({ worldProgress: progress });
    };
    const onTouchEnd = function (e) {
        handleGestureEnd();
    };

    return (
        <StoryCanvasContext.Provider value={{ width, height }}>
            <div className={classes.storyCanvas}
                 onMouseDown={onMouseDown}
                 onMouseMove={onMouseMove}
                 onMouseUp={onMouseUp}
                 onMouseLeave={onMouseUp}
                 onTouchStart={onTouchStart}
                 onTouchMove={onTouchMove}
                 onTouchEnd={onTouchEnd}>
                <Stage width={width} height={height}>
                    {layers.map((layer) => <StoryLayer key={layer.id} layer={layer} />)}
                </Stage>
            </div>
        </StoryCanvasContext.Provider>
    );
}