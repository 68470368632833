import {Stack, Progress, Title, Text, createStyles} from "@mantine/core";

const useStyles = createStyles((theme) => ({
    container: {
        maxWidth: 500,
        height: '100%',
        margin: 'auto',
        justifyContent: 'center',
        background: '#ffffff',
    },
    progressBar: {
        width: '100%',
    }
}));

export default function ResLoadingView({progress}) {
    const {classes} = useStyles();

    return (
        <Stack align="center" className={classes.container} p="xl">
            <Title order={2}>Loading Resources</Title>
            <Progress value={progress} label={`${progress}%`}
                      animate size="xl" radius="xl"
                      className={classes.progressBar} />
            <Text size="sm" align="center">
                We're currently loading image resources. <br />Please wait a moment.
            </Text>
        </Stack>
    );
}