import {Modal, Loader, createStyles, useMantineTheme} from "@mantine/core";
import {useMediaQuery} from '@mantine/hooks';
import {useNavigate, useNavigationType, useParams, NavigationType} from "react-router-dom";
import api from "../../lib/api";
import useSWR from "swr";
import {ErrorScreen} from "@/components/error";
import MantineSchemaForm from "@polyusd-parable/questionnaire-view/form";
import {useAuth} from "@/hooks/auth";

async function fetchSurvey(key, storyId) {
    return (await api.get(`/frontend/story/${storyId}/survey`)).data.questions;
}

function SurveyBody({questions}) {
    const { storyId } = useParams();
    const navigate = useNavigate();
    const { profileMutation } = useAuth();

    const onSubmit = async function (data) {
        // Submit through API
        const res = await api.post(`/frontend/story/${storyId}/survey`, data);

        // Refresh profile
        await profileMutation();

        // Go to success page
        navigate(`/story/${storyId}/completed`);
    }

    return (
        <>
            <MantineSchemaForm schema={ { items: questions } }
                               storageKey={`survey_${storyId}`}
                               onSubmit={onSubmit} />
        </>
    );
}

const useStyles = createStyles((theme) => ({
    modalHeader: {
        paddingTop: theme.spacing.sm,
        paddingBottom: theme.spacing.sm,
        paddingLeft: theme.spacing.lg,
        paddingRight: theme.spacing.lg,
        margin: 0,
        borderBottom: `8px solid ${theme.colors.secondary[0]}`,
    },
    modalTitle: {
        fontSize: theme.headings.sizes.h3.fontSize,
        fontWeight: theme.headings.fontWeight,
        fontFamily: theme.headings.fontFamily,
    }
}));

export default function SurveyPage() {
    const {} = useAuth({ middleware: 'auth' });
    const theme = useMantineTheme();
    const isMdOrAbove = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);

    // Router related
    const { storyId } = useParams();
    const navigate = useNavigate();
    const navigationType = useNavigationType();

    const { classes } = useStyles();
    const { data: questions, error } = useSWR(['story-survey', storyId], fetchSurvey);

    const onClose = () => {
        if (navigationType === NavigationType.Push) {
            navigate(-1);
        } else {
            navigate(`/story/${storyId}`, {replace: true});
        }
    };

    return (
        <Modal opened={true} fullScreen={!isMdOrAbove} overflow="outside" padding={0}
               title="Survey" classNames={{ header: classes.modalHeader, title: classes.modalTitle }}
               onClose={onClose}>
            {error
                ? <ErrorScreen title="Failed to fetch survey data" message="Sorry! We're unable to retrieve the questions at this moment. Please make sure you have a stable Internet connection, or try again later." />
                : (questions ? <SurveyBody questions={questions} /> : <Loader />)
            }
        </Modal>
    );
}