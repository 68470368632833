import {Link, useRouteError} from "react-router-dom";
import { createStyles, Code, Title, Text, Button, Container, Group } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
    },

    label: {
        fontFamily: theme.headings.fontFamily,
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 220,
        lineHeight: 1,
        marginBottom: theme.spacing.xl * 1.5,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],

        [theme.fn.smallerThan('sm')]: {
            fontSize: 120,
        },
    },

    title: {
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 38,

        [theme.fn.smallerThan('sm')]: {
            fontSize: 32,
        },
    },

    description: {
        maxWidth: 500,
        margin: 'auto',
        marginTop: theme.spacing.xl,
        marginBottom: theme.spacing.xl * 1.5,
    },
}));

export default function ErrorPage() {
    const { classes } = useStyles();
    const error = useRouteError();

    const statusCode = error.status || 500;
    const title = error.statusText || 'Oops! An error occurred... 😵';
    const message = error.status === 404 ? 'Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another URL.' : error.message;

    return (
        <Container className={classes.root}>
            <div className={classes.label}>{statusCode}</div>
            <Title className={classes.title}>{title}</Title>

            <Text color="dimmed" size="lg" align="center" className={classes.description}>
                {message}
            </Text>

            { error.stack && process.env.NODE_ENV === 'development' ? <Code block mb="md">{error.stack}</Code> : null }

            <Group position="center">
                <Button variant="subtle" size="md" component={Link} to="/">
                    Take me back to home page
                </Button>
            </Group>
        </Container>
    );
}