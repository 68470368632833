import {createStyles} from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    container: {

    },
    cover: {
        position: 'relative',
        zIndex: 10,
        display: 'flex',
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',
        pointerEvents: 'none',
        // background: 'rgba(255,0,255,0.2)',
    },
    coverBody: {
        background: 'rgba(6,53,122,0.8)',
        color: '#ffffff',
        padding: `${theme.spacing.xl}px ${theme.spacing.lg}px`,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: theme.headings.sizes.h3.fontSize,
        boxShadow: '0 0 4px rgb(0 0 0 / 40%)',
    },
    layer: {
        position: 'fixed',
        zIndex: 5,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    track: {
        width: '100%',
        height: '100%',
    },
    frame: {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    storyMainView: {
        background: '#cccccc',
    },
    storyCanvasContainer: {
        overflow: 'hidden',
        position: 'fixed',
        zIndex: 5,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
    },
    storyCanvas: {
        cursor: 'grab',
        '&:active': {
            cursor: 'grabbing',
        },
        '.konvajs-content': {
            pointerEvents: 'none',
        }
    }
}));