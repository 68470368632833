import {Outlet} from "react-router-dom";
import {AppShell, MantineProvider} from '@mantine/core';
import {theme} from "./styles/theme";
import './styles/globals.scss';

function App() {
  return (
      <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
          <AppShell padding={0}>
              <Outlet />
          </AppShell>
      </MantineProvider>
  );
}

export default App;
