import {Container, createStyles, Header} from "@mantine/core";
import {useAuth} from "@/hooks/auth";
import UserProgress from "@/components/layout/progress";
import logo from "./header_logo.svg";

const HEADER_HEIGHT = 56;
const useStyles = createStyles((theme) => ({
    header: {
        backgroundColor: theme.fn.variant({variant: 'filled', color: theme.primaryColor}).background,
        borderBottom: 0,
    },
    logo: {
        width: 44,
        height: 40,
        marginRight: theme.spacing.md,
    },
    inner: {
        height: HEADER_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

export default function AppHeader() {
    const { classes } = useStyles();
    const { profile } = useAuth();

    return (
        <Header height={HEADER_HEIGHT} className={classes.header}>
            <Container className={classes.inner}>
                <img src={logo} alt="" className={classes.logo} />
                {profile ? <UserProgress profile={profile} /> : null}
            </Container>
        </Header>
    );
}