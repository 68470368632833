import {useNavigate} from "react-router-dom";
import {useAuth} from "@/hooks/auth";

export function useNextStory() {
    const {profile} = useAuth();
    const navigate = useNavigate();

    return {
        goToNextStory: function () {
            if (!profile) return;

            const completed = new Set(profile.completed);
            const ids = profile.available.filter(i => !completed.has(i));

            if (ids.length === 0) return;

            const id = ids[Math.floor(Math.random() * ids.length)];
            navigate('/story/'+id);
        }
    }
}