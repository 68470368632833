import {joiResolver, useForm} from '@mantine/form';
import Joi from 'joi';
import {Button, createStyles, TextInput, Title} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import logo from "./logo_colored.svg";

const schema = Joi.object({
    invite_code: Joi.string()
        .min(4)
        .max(8)
        .alphanum()
        .label('Invitation Code'),
});

const useStyles = createStyles((theme) => ({
    logo: {
        textAlign: 'center',
        img: {
            width: 156,
        },
        marginTop: 48,
        marginBottom: 24,
    },
    title: {
        textAlign: 'center',
        marginBottom: 36,
    }
}));

export default function InviteCodeForm() {
    const { classes } = useStyles();
    const form = useForm({
        initialValues: {
            invite_code: '',
        },
        validate: joiResolver(schema),
    });

    const navigate = useNavigate();
    const onSubmit = function ({invite_code}) {
        // Go to invite code checking page
        navigate(`/go/${invite_code}`);
    }

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <div className={classes.logo}>
                <img src={logo} alt="" />
            </div>

            <Title className={classes.title}>Welcome</Title>

            <TextInput mb="xl"
                       placeholder="Please enter your invitation code"
                       {...form.getInputProps('invite_code')} />

            <Button fullWidth type="submit">
                Continue
            </Button>
        </form>
    );
}