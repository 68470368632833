import {useNavigate, useParams, Link} from "react-router-dom";
import {useAuth} from "../hooks/auth";
import {useEffect, useState} from "react";
import {Button, Title, Text, Loader, Stack, useMantineTheme, Container} from "@mantine/core";
import { Icon } from '@iconify/react'
import ErrorIcon from '@iconify-icons/material-symbols/error-outline'
import {ErrorScreen} from "../components/error";

function Parent({children}) {
    return (
        <Container size={330} pt={32}>
            {children}
        </Container>
    )
}

export default function InviteCodePage() {
    const {inviteCode} = useParams();
    const {login} = useAuth();
    const navigate = useNavigate();
    const theme = useMantineTheme();

    const [error, setError] = useState(null);

    useEffect(() => {
        console.log('useMount', inviteCode);
        login({invite_code: inviteCode}).then(function () {
            // Go back to home page
            navigate('/');
        }).catch(function (e) {
            setError(e);
            console.error(e);
        });
    }, [inviteCode]);

    if (error) {
        return (
            <Parent>
                <ErrorScreen
                    title="Invalid invitation code"
                    message={<>The invitation code <strong>{inviteCode}</strong> that you've entered is incorrect. Please check the invitation message again for your code.</>}
                    action={(
                        <Button variant="outline" size="sm" fullWidth component={Link} to="/">
                            Back to home
                        </Button>
                    )}
                />
            </Parent>
        );
    }

    return (
        <Parent>
            <Stack align="center">
                <Loader size={64} />
                <Text>Verifying the invitation code...</Text>
            </Stack>
        </Parent>
    );
}