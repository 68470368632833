import { Button, Box, Alert, createStyles } from "@mantine/core";
import { createFormContext } from "@mantine/form";
import {FieldBody} from "./types";
import {buildInitialValues, buildValidators} from "./validator";
import {useMemo, useState} from "react";
import { Icon } from "@iconify/react";
import ErrorIcon from '@iconify-icons/material-symbols/error-outline';
import {RememberedForm} from "./storage";

const useStyles = createStyles((theme) => ({
    row: {
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
        paddingLeft: theme.spacing.lg,
        paddingRight: theme.spacing.lg,
        '&:nth-of-type(even)': {
            backgroundColor: theme.colors.secondary[0]
        }
    },
    footer: {
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
        paddingLeft: theme.spacing.lg,
        paddingRight: theme.spacing.lg,
        borderTop: `8px solid ${theme.colors.secondary[0]}`,
    },
    question: {
        fontWeight: '500',
        fontSize: theme.fontSizes.md,
        color: theme.colors.gray[8],
        marginBottom: theme.spacing.md,
    },
    scaleMark: {
        fontSize: theme.fontSizes.xs,
        color: theme.colors.gray[6],
    },
    scaleLabel: {
        whiteSpace: 'normal',
        wordBreak: 'normal',
        top: 'auto',
        bottom: 24
    },
    scaleBar: {
        backgroundColor: theme.colors.gray[2]
    },
    scaleMarkFilled: {
        borderColor: theme.colors.gray[2]
    },
}));
const [FormProvider, useFormContext, useForm] = createFormContext();
export const useSchemaFormContext = useFormContext;

export default function MantineSchemaForm({ schema, onSubmit, storageKey }) {
    const { classes } = useStyles();
    const form = useForm({
        initialValues: buildInitialValues(schema),
        validate: buildValidators(schema),
    });

    function formRowBuilder(item, index) {
        return (
            <div key={item.id} className={classes.row}>
                <FieldBody item={item} classes={classes} index={index} />
            </div>
        );
    }

    const items = schema.items;
    const body = items.map(formRowBuilder);

    const clientError = useMemo(() => {
        const keys = Object.keys(form.errors);
        const indices = keys.map(key => schema.items.findIndex(i => i.id === key)+1).filter(i => i > 0);
        if (indices.length === 0) return null;

        return `Please review question #${indices.join(', ')}`;
    }, [form.errors]);

    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState(null);
    const onFormSubmit = async function () {
        setLoading(true);
        try {
            setServerError(null);
            await onSubmit(form.values);

            // Clear session storage after submission
            window.sessionStorage.removeItem(storageKey);
        } catch (e) {
            setServerError(e.toString());
        }
        setLoading(false);
    };

    return (
        <div>
            <FormProvider form={form}>
                <RememberedForm storageKey={storageKey}>
                    <form onSubmit={form.onSubmit(onFormSubmit)}>
                        { body }
                        <Box className={classes.footer}>
                            {clientError ? (<Alert icon={<Icon icon={ErrorIcon} />} mb="md" color="red">{clientError}</Alert>) : null}
                            {serverError ? (<Alert icon={<Icon icon={ErrorIcon} />} mb="md" color="red">{serverError}</Alert>) : null}
                            <Button fullWidth type="submit" loading={loading}>Submit</Button>
                        </Box>
                    </form>
                </RememberedForm>
            </FormProvider>
        </div>
    );
}