import AppHeader from "@/components/layout/header";
import {Container} from "@mantine/core";
import InviteCodeForm from "@/components/guest/invite_code_form";
import {useAuth} from "@/hooks/auth";

export default function GuestHomePage() {
    const {} = useAuth({ middleware: 'guest' });

    return (
        <>
            <AppHeader />
            <Container>
                <InviteCodeForm />
            </Container>
        </>
    );
}