import {
    createBrowserRouter,
} from "react-router-dom";
import App from "./App";
import IndexPage from "./pages";
import InviteCodePage from "./pages/invited";
import StoryIndexPage from "./pages/story/view";
import SurveyPage from "./pages/story/survey";
import CompletedPage from "./pages/story/completed";
import GuestHomePage from "./pages/home/guest";
import UserHomePage from "./pages/home/user";
import ErrorPage from "./components/ErrorPage";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            { path: "", element: <IndexPage /> },
            { path: "login", element: <GuestHomePage /> },
            { path: "home", element: <UserHomePage /> },
            { path: "go/:inviteCode", element: <InviteCodePage /> },
            {
                path: "story/:storyId",
                element: <StoryIndexPage />,
                children: [
                    { path: "survey", element: <SurveyPage /> },
                ],
            },
            { path: "story/:storyId/completed", element: <CompletedPage /> },
        ],
    }
]);