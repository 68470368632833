import {Icon} from "@iconify/react";
import ErrorIcon from "@iconify-icons/material-symbols/error-outline";
import {Stack, Text, Title, useMantineTheme} from "@mantine/core";

export function ErrorScreen({title, message, action}) {
    const theme = useMantineTheme();

    return (
        <Stack align="center">
            <Icon icon={ErrorIcon} width={64} height={64} color={theme.colors.red[8]} />
            <Title order={2}>{title}</Title>
            <Text size="sm" align="center">{message}</Text>
            {action}
        </Stack>
    )
}