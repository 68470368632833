import AppHeader from "@/components/layout/header";
import {Box, Button, Container, Divider, Text, Title, useMantineTheme} from "@mantine/core";
import {Icon} from "@iconify/react";
import CheckIcon from '@iconify-icons/material-symbols/check-circle-outline-rounded'
import ChevronRightIcon from '@iconify-icons/material-symbols/chevron-right-rounded'
import {AllCompletedBody} from "@/components/message/AllCompletedBody";
import {useAuth} from "@/hooks/auth";
import {useNextStory} from "@/hooks/story";

function BodyNotAllCompleted() {
    const theme = useMantineTheme();
    const {goToNextStory} = useNextStory();

    return (
        <Container>
            <Box py="xl" style={{ textAlign: 'center' }}>
                <Icon icon={CheckIcon} width={64} height={64} color={theme.colors.primary[6]} />
                <Title order={2} align="center">Well received!</Title>
                <Text size="sm" color="dimmed" align="center">Feel free to take a break</Text>
                <Divider label="or" labelPosition="center" my="md" />
                <Button fullWidth rightIcon={<Icon icon={ChevronRightIcon} />} onClick={goToNextStory}>
                    Start a new one
                </Button>
            </Box>
        </Container>
    );
}

export default function CompletedPage() {
    const {profile} = useAuth({ middleware: 'auth' });

    return (
        <>
            <AppHeader />
            {profile.completed.length >= profile.available.length ? <AllCompletedBody /> : <BodyNotAllCompleted />}
        </>
    );
}