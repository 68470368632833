import {Image, Layer} from 'react-konva';
import {useContext, useEffect, useMemo, useRef} from "react";
import {StoryContext} from "../context";
import {StoryCanvasContext} from "./canvas";
import {rangeMap} from "../helper";

function getFrameByProgress(sequences, progress) {
    const k = Math.floor(sequences.length * progress);
    const seq = sequences[Math.min(k, sequences.length-1)];
    return seq?.id;
}

function Frame({seqId, opacityMap}) {
    const ref = useRef(null);
    const { width, height } = useContext(StoryCanvasContext);
    const { state } = useContext(StoryContext);

    const image = state.resCache[seqId];
    const cropData = useMemo(() => {
        if (!image) return null;

        let newWidth;
        let newHeight;

        const aspectRatio = width / height;
        const imageRatio = image.width / image.height;

        // Calculate new image size
        if (aspectRatio >= imageRatio) {
            newWidth = image.width;
            newHeight = image.width / aspectRatio;
        } else {
            newWidth = image.height * aspectRatio;
            newHeight = image.height;
        }

        return {
            x: (image.width - newWidth) / 2,
            y: (image.height - newHeight) / 2,
            width: newWidth,
            height: newHeight,
        };
    }, [image, width, height]);

    useEffect(() => {
        if (!ref.current) return;

        const getOpacity = () => {
            const v = state.world + state.worldProgress;

            // Blended mode
            if (v === 0) return opacityMap[1];

            // Behaviour to Blended
            if (v < 0) {
                return rangeMap(v, -1, 1, opacityMap[0], opacityMap[1]);
            }

            // Blended to Comparable
            return rangeMap(v, 0, 1, opacityMap[1], opacityMap[2]);
        };

        const opacity = getOpacity();
        if (state.isWorldTransition) {
            // Directly set opacity
            ref.current.opacity(opacity);
        } else {
            // Change opacity gradually
            ref.current.to({
                opacity: opacity,
                duration: 0.2,
            });
        }

    }, [opacityMap, state.world, state.worldProgress, state.isWorldTransition]);

    if (!image) return null;

    return (
        <Image
            ref={ref}
            image={image} x={0} y={0} width={width} height={height} crop={cropData}
            opacity={0}
        />
    );
}

export function StoryLayer({ layer }) {
    const { state } = useContext(StoryContext);

    const img = useMemo(() => {
        return {
            behaviour: getFrameByProgress(layer.tracks.behaviour.sequences, state.progress),
            comparable: getFrameByProgress(layer.tracks.comparable.sequences, state.progress),
        };
    }, [layer.tracks, state.progress]);

    return (
        <Layer>
            {/* Behaviour */}
            <Frame seqId={img['behaviour']}
                   opacityMap={[1, layer.tracks.behaviour.blend_mode_visible ? 1 : 0, 0]} />

            {/* Comparable */}
            <Frame seqId={img['comparable']}
                   opacityMap={[0, layer.tracks.comparable.blend_mode_visible ? 1 : 0, 1]} />
        </Layer>
    );
}
