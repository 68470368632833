import axios from "axios";

function findLayerImages(layer) {
    return [
        layer.tracks.behaviour.sequences,
        layer.tracks.comparable.sequences,
    ];
}

function findIterationImages(iteration) {
    return iteration.layers.map(findLayerImages);
}

export function findImagesFromIterations(iterations) {
    return iterations.map(findIterationImages).flat(3);
}

function preloadImage(data) {
    return new Promise(function (resolve, reject) {
        const img = new Image();
        img.onload = function () {
            resolve({id: data.id, url: data.url, object: img});
        };
        img.onerror = function (e) {
            reject(e);
        };
        img.src = data.url;
    });
}

export function preloadImages(images, { onProgress }) {
    const progress = { total: images.length, success: 0, failed: 0 };

    return Promise.allSettled(images.map((img) => {
        return preloadImage(img).then(function (res) {
            progress.success++;
            return res;
        }).catch(function (e) {
            progress.failed++;
            return e;
        }).finally(function () {
            onProgress(progress);
        });
    }));
}

/*
    Find max no. of frames of an iteration
 */
export function findMaxFrames(iteration) {
    let max = 0;
    for (const layer of iteration.layers) {
        const m = Math.max(layer.tracks.behaviour.sequences.length, layer.tracks.comparable.sequences.length);
        if (m > max) {
            max = m;
        }
    }

    return max;
}

export function computeMarks(iterations, step, vpHeight) {
    const result = [];
    let y = 0;

    for (let k in iterations) {
        const iteration = iterations[k];
        const frames = findMaxFrames(iteration);

        const height = frames * step + vpHeight;
        const end = y + height;
        result.push({
            id: iteration.id,
            start: y,
            end: end,
            height: height,
            maxFrames: frames
        });

        y = end;
    }

    return result;
}

export function rangeMap(value, in_min, in_max, out_min, out_max) {
    return (value - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}