import {useSchemaFormContext} from "./form";
import {useEffect} from "react";
import defaults from 'lodash/defaults';

export function RememberedForm({storageKey, children}) {
    const form = useSchemaFormContext();
    const storage = window.sessionStorage;

    useEffect(() => {
        // Restore stored values
        if (storageKey) {
            const storedValue = storage.getItem(storageKey);
            if (storedValue) {
                try {
                    const stored = JSON.parse(storage.getItem(storageKey));
                    form.setValues(defaults({}, stored, form.values));
                } catch (e) {
                    console.log('Failed to parse stored value');
                }
            }
        }
    }, []);

    // Save updated values to session when the form is changed
    useEffect(() => {
        if (storageKey) {
            try {
                storage.setItem(storageKey, JSON.stringify(form.values));
            } catch (e) {
                console.log('Failed to cache the response value')
            }
        }
    }, [storageKey, form.values]);

    return children;
}