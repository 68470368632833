import {Loader, Stack, Text} from "@mantine/core";
import {useAuth} from "@/hooks/auth";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

function Loading() {
    return (
        <Stack align="center">
            <Loader size={64} mt={32} />
            <Text>Loading your current status...</Text>
        </Stack>
    );
}

export default function IndexPage() {
    const {profile, isReady} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isReady) {
            navigate(profile ? '/home' : 'login');
        }
    }, [profile, isReady]);

    return <Loading />;
}