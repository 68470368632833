import IterationCover from "./cover";
import {useStyles} from "./styles";
import {StoryCanvas} from "./canvas";

export function IterationView({iteration, height}) {
    const { classes } = useStyles();

    return (
        <div className={classes.container} style={{ height }}>
            <IterationCover title={iteration.title} />
        </div>
    );
}

export function StoryMain({ children, iterations, width, height }) {
    const { classes } = useStyles();

    return (
        <div className={classes.storyMainView}>
            {children}

            <div className={classes.storyCanvasContainer}>
                {iterations.length > 0
                    ? <StoryCanvas width={width} height={height} iterations={iterations} />
                    : <div>This story is not yet configured</div>}
            </div>
        </div>
    );
}