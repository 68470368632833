import booleanField from "./boolean";
import scaleField from "./scale";
import {createElement} from "react";

export const fieldTypes = {
    'boolean': booleanField,
    'scale': scaleField,
}

export function FieldBody({item, classes, index}) {
    const type = fieldTypes[item.type];
    if (!type)
        return (<div>Unsupported field type {item.type}</div>);

    return createElement(type.Field, { item, classes, index });
}