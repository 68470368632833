import {createStyles, Button, Text, Container} from "@mantine/core";
import {Link, useParams} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    endView: {
        position: 'relative',
        zIndex: 10,
        background: 'rgba(6,53,122,0.8)',
        width: '100%',
        height: '100vh',
        color: '#ffffff',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: theme.headings.sizes.h3.fontSize,
        padding: '0 16px',
    },
    endViewContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
    }
}));

export default function StoryEndView() {
    const { classes } = useStyles();
    const { storyId } = useParams();

    return (
        <div className={classes.endView}>
            <Container className={classes.endViewContainer}>
                <Text mb="xl">A short survey is waiting</Text>
                <Button fullWidth variant="default" component={Link} to={`/story/${storyId}/survey`}>
                    Start
                </Button>
            </Container>
        </div>
    );
}